<template>
  <b-card title="Job Template Items">
    <app-timeline>
      <template
        v-for="(timelineItem, index) in timelineItems"
      >
        <app-timeline-item
          :key="index"
          :icon="resolveTimelineTypeIcon(timelineItem.type)"
          variant="secondary"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ resolveTimelineTypeText(timelineItem.type) }}</h6>
          </div>
          <p>{{ timelineItem.description }}</p>
        </app-timeline-item>
      </template>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useJobProgressList from '@/views/job/jobs-view/useJobProgressList'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
  },

  props: {
    jobItems: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const {
      resolveTimelineTypeText,
      resolveTimelineTypeIcon,
    } = useJobProgressList()

    const timelineItems = props.jobItems
    return {
      timelineItems,
      resolveTimelineTypeText,
      resolveTimelineTypeIcon,
    }
  },
}
</script>

<style>

</style>
