<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="jobTemplateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching job template data
      </h4>
      <div class="alert-body">
        No job template found with this  id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'job-templates-list'}"
        >
          Job Templates List
        </b-link>
        for other job templates.
      </div>
    </b-alert>

    <template v-if="jobTemplateData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <job-template-info-card
            :job-template-data="jobTemplateData"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="6"
          md="6"
        >
          <job-template-items-card
            :job-items="jobTemplateData.jobItems"
          />
        </b-col>

      </b-row>
      </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import jobTemplateStoreModule from '@/views/job-templates/jobTemplateStoreModule'
import jobTemplateInfoCard from '@/views/job-templates/job-templates-view/JobTemplateInfoCard.vue'
import jobTemplateItemsCard from '@/views/job-templates/job-templates-view/JobTemplateItemsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    jobTemplateInfoCard,
    jobTemplateItemsCard,
  },

  setup() {
    const jobTemplateData = ref(null)

    const JOB_TEMPLATE_APP_STORE_MODULE_NAME = 'app-job-template'

    // Register module
    if (!store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME, jobTemplateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-job-template/fetchJobTemplate', { id: router.currentRoute.params.id })
      .then(response => {
        jobTemplateData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobTemplateData.value = undefined
        }
      })
    return {
      jobTemplateData,
    }
  },
}
</script>

<style>

</style>
